import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPage } from '../Page';
import ActionCreator from '../ActionCreator';
import * as L from '../Utils/Lang';
import * as Icon from '../Components/Icon';
import ProductsSection from '../Components/ProductsSection';

let NormalProducts = [
    { img: 'photo16.png' },
    { img: 'photo17.png' },
    { img: 'photo18.png' },
    { img: 'photo19.png' },
];

let PhysicalChannel = [
    { img: 'photo20.png' },
    { img: 'photo21.png' },
    { img: 'photo22.png' },
];

class DataProductsPage extends React.Component {
    render() {
        let { navActions } = this.props;
        return (
            <Wrapper>
                <Container>
                    <Banner img='/images/redesign/photo15.jpg' css='margin-bottom: 81px;'>
                        <button onClick={() => navActions.push('/products')}>
                            {L.s('products')}
                            <Icon.KeyboardArrowRight color='#0075C1' style={{ width: 32, height: 32 }}  />
                        </button>
                    </Banner>

                    <ProductsSection
                        title={L.s('normal-products')}
                        data={NormalProducts}
                    />

                    <ProductsSection
                        title={L.s('physical-channel')}
                        data={PhysicalChannel}
                    />
                </Container>
            </Wrapper>
        )
    }
}


const Wrapper = styled.div`
    padding-top: 150px;
    width: 100vw;
`;

const Container = styled.div`
    margin: 0 auto;
    max-width: 1120px;
`;

const Banner = styled.div`
    background-color: lightgray;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    position: relative;
    max-width: 1110px;
    width: 100%;
    height: 502px;

    & > button {
        position: absolute;
        left: 0px;
        bottom: 87px;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
        border: 0px;
        padding: 15px 13px;
        background-color: #FFFFFF;
        letter-spacing: 1.8px;
        font-size: 30px;
        color: #0075C1;

        :focus {
            outline: none;
        }
    }

    @media screen and (max-width: 375px) {
        height: 300px;

        & > button {
            bottom: 46px;
            letter-spacing: 1.2px;
            font-size: 20px;
        }
    }

    ${props => props.css};
`;

export default withPage(connect(
    null,
    ActionCreator
)(DataProductsPage));
