import React from 'react';
import styled from 'styled-components';

class ProductsSection extends React.Component {
    render() {
        let { title='', data } = this.props;
        return (
            <Wrapper css='margin-bottom: 81px;'>
                <div className='title'>{title}</div>
                <div className='content'>
                    {
                        data &&
                        data.map((d, i) =>
                            <ProductItem
                                key={title + i}
                                img={`/images/redesign/${d.img}`}
                                last={i % 2 === 1}
                            />
                        )
                    }
                </div>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    max-width: 100%;

    & > .title {
        margin-bottom: 60px;
        text-align: center;
        letter-spacing: 1.8px;
        font-size: 30px;
        color: #0075C1;
    }

    & > .content {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    @media screen and (max-width: 599px){
        & > .content {
            justify-content: center;
        }
    }

    ${props => props.css};
`;

const ProductItem = styled.div`
    background-color: lightgray;
    background-image: url(${props => props.img});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    margin-right: ${props => props.last ? 0 : 20}px;
    margin-bottom: 20px;
    max-width: 540px;
    width: 100%;
    height: 540px;

    @media screen and (max-width: 1119px) {
        max-width: 500px;
        height: 500px;
    }

    @media screen and (max-width: 1040px) {
        max-width: 480px;
        height: 480px;
    }

    @media screen and (max-width: 1000px) {
        max-width: 400px;
        height: 400px;
    }

    @media screen and (max-width: 840px) {
        max-width: 364px;
        height: 364px;
    }

    @media screen and (max-width: 768px) {
        max-width: 340px;
        height: 340px;
    }

    @media screen and (max-width: 600px) {
        max-width: 280px;
        height: 280px;
    }

    @media screen and (max-width: 599px){
        margin-right: 0px;
        max-width: 335px;
        height: 335px;
    }

    @media screen and (max-width: 320px){
        margin-right: 0px;
        max-width: 300px;
        height: 300px;
    }
    
    ${props => props.css}
`;

export default ProductsSection;